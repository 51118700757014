import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import BaseSingleSelect = require("Everlaw/UI/BaseSingleSelect");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Util = require("Everlaw/Util");
import dojo_on = require("dojo/on");

/**
 * A class for using UI.SingleWithToggler instead of the built-in browser select.
 */
class ToggledSingleSelect<O extends Base.Object> {
    node: HTMLElement;
    // We need to display the select div that wraps our toggler prior to SingleWithToggler actually
    // being created. By passing selectDiv to the SingleWithToggler constructor below, it will be
    // used directly rather than SingleWithToggler creating its own.
    private selectDiv: HTMLElement;
    private selectedTextDiv: HTMLElement;
    private selectedText: HTMLElement;
    private elements: O[];
    private singleSelect: BaseSingleSelect.SingleWithToggler<O>;
    // We use these variables to keep track of the current state of the widget, so that when
    // SingleWithToggler is finally created, we can set it to the appropriate state.
    private curIndex = 0;
    private selectDisabled = false;
    private elemDisabled: boolean[];
    private onChange: (obj: O, index: number) => void;
    private toDestroy: Util.Destroyable[] = [];
    protected elementDisplay: (element: O) => string = (e) => e.display();
    private alternateDisabledDisplay: HTMLElement;
    constructor(params: ToggledSingleSelect.Params<O>) {
        this.selectedTextDiv = Dom.div({ class: "toggled-single-select-text" }, [
            (this.selectedText = Dom.span()),
            new Icon("caret-down-20").node,
        ]);
        this.selectDiv = Dom.div({ class: "toggled-single-select" }, this.selectedTextDiv);
        this.node = Dom.div(
            {
                class: `v-spaced-8 ${params.styleClass ? params.styleClass : ""}`,
                role: "combobox",
            },
            params.label ? Dom.div(Dom.span({ class: "h7" }, params.label)) : null,
            this.selectDiv,
        );
        if (params.parent) {
            Dom.place(this.node, params.parent);
        }
        params.width && Dom.style(this.selectDiv, { width: `${params.width}px` });
        params.height && Dom.style(this.selectDiv, { height: `${params.height}px` });
        this.elements = params.options;
        this.elemDisabled = this.elements.map((e) => false);
        this.onChange = params.onChange;
        const selectParams: BaseSingleSelect.Params<O> = {
            selectDiv: this.selectDiv,
            toggler: this.selectedTextDiv,
            elements: this.elements,
            comparator: (x, y) => this.elements.indexOf(x) - this.elements.indexOf(y),
            headers: false,
            selectOnSame: true,
            minimizePopupOnAll: true,
            colorItems: params.colorItems,
            popup: "after",
            style: params.style,
            onSelect: (element) => {
                this.curIndex = this.elements.indexOf(element);
                this.setSelectedText();
                this.onChange && this.onChange(element, this.curIndex);
            },
        };
        // Have to do this instead of passing in an undefined because of the Object.assign() in the
        // BaseSelect constructor.
        if (params.prepRowElement) {
            selectParams.prepRowElement = params.prepRowElement;
        }
        if (params.default) {
            if (Is.string(params.default)) {
                this.setText(params.default);
            } else {
                this.setCurrentObject(params.default, true);
            }
        }
        if (params.display) {
            selectParams.display = params.display;
            this.elementDisplay = params.display;
        }
        if (Is.defined(params.matchWidth)) {
            selectParams.matchWidth = params.matchWidth;
        }
        if (params.popupClass) {
            selectParams.popupClass = params.popupClass;
        }
        // Design sometimes uses a disabled version of this class that looks like a fully greyed-out
        // box with no triangle. Setting this parameter renders that design when disabled.
        if (params.alternateDisabledDisplayText) {
            this.alternateDisabledDisplay = Dom.div(
                {
                    class: "disabled-single-select",
                    style: { width: `${params.width}px` },
                },
                params.alternateDisabledDisplayText,
            );
            Dom.place(this.alternateDisabledDisplay, params.parent);
            Dom.hide(this.alternateDisabledDisplay);
        }
        // Creating a Select can be time consuming so we do it lazily.
        this.toDestroy.push(
            dojo_on.once(this.selectDiv, Input.enter, (e) => {
                this.singleSelect = new BaseSingleSelect.SingleWithToggler(selectParams);
                this.toDestroy.push(this.singleSelect);
                this.setSelectIndex();
                this.singleSelect.setDisabled(this.selectDisabled);
                for (let idx = 0; idx < this.elements.length; idx++) {
                    this.setElemDisabled(idx, this.elemDisabled[idx]);
                }
            }),
        );
    }
    setCurrentObject(obj: O, silent?: boolean): void {
        this.setCurrentIndex(Arr.indexOf(this.elements, obj), silent);
    }
    getCurrentObject(): O {
        return this.elements[this.curIndex];
    }
    getCurrentIndex(): number {
        return this.curIndex;
    }
    setCurrentIndex(index: number, silent?: boolean): void {
        this.curIndex = index;
        this.setSelectedText();
        this.singleSelect && this.setSelectIndex();

        if (!silent) {
            // We need to manually trigger this.onChange here, since this.setSelectIndex does not
            // trigger this.singleSelect.onSelect above.
            this.onChange && this.onChange(this.elements[this.curIndex], this.curIndex);
        }
    }
    setDisabled(disabled: boolean): void {
        this.selectDisabled = disabled;
        this.singleSelect && this.singleSelect.setDisabled(disabled);
        if (this.alternateDisabledDisplay) {
            Dom.show(this.node, !disabled);
            Dom.show(this.alternateDisabledDisplay, disabled);
        } else {
            // Calling setDisabled on the select doesn't set a state on the node. I'm wary of adding
            // this to the class itself due to possible side-effects elsewhere on the platform, so for
            // now I'm just doing it here locally.
            Dom.toggleClass(this.selectDiv, "disabled", disabled);
        }
    }
    setAlternateDisplayText(displayText: string): void {
        this.alternateDisabledDisplay.innerText = displayText;
    }
    setElemDisabled(index: number, disabled: boolean): void {
        this.elemDisabled[index] = disabled;
        this.singleSelect && this.singleSelect.setElemDisabled(this.elements[index], disabled);
    }
    isElemDisabled(index: number): boolean {
        return this.elemDisabled[index];
    }
    select(obj: O, silent?: boolean): void {
        this.singleSelect && this.singleSelect.select(obj, silent);
    }
    private setSelectIndex(): void {
        if (this.curIndex === this.elements.length) {
            // Corresponds to the "Custom" option so unselect the current selection (if any).
            const selected = this.singleSelect.getSelected();
            selected && this.singleSelect.unselect(selected, true);
        } else {
            this.singleSelect.select(this.elements[this.curIndex], true);
        }
    }
    private setSelectedText(): void {
        this.selectedText.textContent = this.selectDisabled
            ? ""
            : this.curIndex === this.elements.length
              ? "Custom"
              : this.elementDisplay(this.elements[this.curIndex]);
    }
    setText(text: string): void {
        this.selectedText.textContent = text;
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ToggledSingleSelect {
    export interface Params<O extends Base.Object> {
        options: O[];
        style?: Dom.StyleProps;
        styleClass?: string;
        display?: (objOrName: O | string) => string;
        prepRowElement?: (e: O) => BaseSelect.Row;
        default?: O | string;
        onChange?: (obj: O, index: number) => void;
        parent?: HTMLElement;
        width?: number;
        matchWidth?: boolean;
        popupClass?: string;
        height?: number;
        label?: Dom.Content;
        colorItems?: string;
        alternateDisabledDisplayText?: string;
    }
}

export = ToggledSingleSelect;
