import Base = require("Everlaw/Base");
import Project = require("Everlaw/Project");
import Dom = require("Everlaw/Dom");
import Checkbox = require("Everlaw/UI/Checkbox");
import ToggledSingleSelect = require("Everlaw/UI/ToggledSingleSelect");
import Rest = require("Everlaw/Rest");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import { Organization } from "Everlaw/Organization";

export enum ProviderDefault {
    NONE = "NONE",
    ANY = "ANY",
}

export enum TranslationProvider {
    AMAZON = "AMAZON",
    GOOGLE = "GOOGLE",
}

export enum LlmSummaryLength {
    AUTOMATIC = "AUTOMATIC",
    SHORT = "SHORT",
    LONG = "LONG",
}

type Provider = TranslationProvider;

function resolveOrgProvider<X>(
    server: X | ProviderDefault,
    org: X | ProviderDefault,
): X | ProviderDefault {
    if ([server, org].some((v) => v === ProviderDefault.NONE)) {
        return ProviderDefault.NONE;
    }
    for (const val of [server, org]) {
        if (val !== ProviderDefault.ANY) {
            return val;
        }
    }
    return ProviderDefault.ANY;
}

function resolveProvider<X>(
    server: X | ProviderDefault,
    org: X | ProviderDefault,
    proj: X | ProviderDefault.NONE,
): X | ProviderDefault.NONE {
    const orgProvider = resolveOrgProvider(server, org);
    if (orgProvider === ProviderDefault.NONE || proj === ProviderDefault.NONE) {
        return ProviderDefault.NONE;
    }
    return orgProvider === ProviderDefault.ANY ? proj : orgProvider;
}

export function isServerGenAIEnabled(): boolean {
    return JSP_PARAMS.Server.isGenAiEnabled;
}

abstract class ProvidedService<P extends Provider> {
    protected constructor(
        readonly name: string,
        readonly allProviders: P[],
        readonly helpUrl: string,
        readonly orgUpdateEndpoint: string,
        readonly projUpdateEndpoint: string,
    ) {}

    abstract getServerSetting(): P | ProviderDefault;
    abstract getOrgSetting(org: Organization): P | ProviderDefault;
    abstract getProjectSetting(proj: Project.CurrentProject): P | ProviderDefault.NONE;
    abstract setOrgSetting(org: Organization, val: P | ProviderDefault): void;
    abstract setProjectSetting(proj: Project.CurrentProject, val: P | ProviderDefault.NONE): void;
    abstract displayProvider(val: P | ProviderDefault.NONE): string;

    isEnabled(): boolean {
        return this.getCurrentProjectProvider() !== ProviderDefault.NONE;
    }

    private getCurrentProjectProvider(): P | ProviderDefault.NONE {
        const serverSetting = this.getServerSetting();
        if (!Project.CURRENT) {
            return ProviderDefault.NONE;
        }
        const orgSetting = this.getOrgSetting(
            Base.get(Organization, Project.CURRENT.owningOrganizationId),
        );
        const projSetting = this.getProjectSetting(Project.CURRENT);
        return resolveProvider(serverSetting, orgSetting, projSetting);
    }
}

const MACHINE_TRANSLATION_HELP_URL =
    "https://support.everlaw.com/hc/en-us/articles/360024408132-General-Settings#h_c1387724-76c9-416e-b8a9-3b1e205d305b";
const MACHINE_TRANSLATION_ORG_UPDATE_ENDPOINT = "/setOrgTranslationProvider.rest";
const MACHINE_TRANSLATION_PROJ_UPDATE_ENDPOINT = "setTranslationProvider.rest";

export const TRANSLATION = new (class extends ProvidedService<TranslationProvider> {
    constructor() {
        super(
            "Machine Translation",
            Object.values(TranslationProvider),
            MACHINE_TRANSLATION_HELP_URL,
            MACHINE_TRANSLATION_ORG_UPDATE_ENDPOINT,
            MACHINE_TRANSLATION_PROJ_UPDATE_ENDPOINT,
        );
    }
    getServerSetting(): TranslationProvider | ProviderDefault {
        const val = JSP_PARAMS.Server.providers.translation;
        if (val === ProviderDefault.NONE || val === ProviderDefault.ANY) {
            return ProviderDefault[val];
        }
        return TranslationProvider[val];
    }
    getOrgSetting(org: Organization): TranslationProvider | ProviderDefault {
        return org.translationProvider;
    }
    getProjectSetting(proj: Project.CurrentProject): TranslationProvider | ProviderDefault.NONE {
        return proj.translationProvider;
    }
    setOrgSetting(org: Organization, val: TranslationProvider | ProviderDefault): void {
        org.translationProvider = val;
    }
    setProjectSetting(
        proj: Project.CurrentProject,
        val: TranslationProvider | ProviderDefault.NONE,
    ): void {
        proj.translationProvider = val;
    }
    displayProvider(val: TranslationProvider | ProviderDefault.NONE): string {
        if (val === ProviderDefault.NONE) {
            return "No translation";
        } else if (val === TranslationProvider.AMAZON) {
            return "AWS Translate";
        } else if (val === TranslationProvider.GOOGLE) {
            return "Google Translate";
        }
    }
})();

/**
 * This class (and its concrete subclasses) is used to configure a provider at
 * the organization level.
 * Given the server-level configuration, there are a few different possible ways
 * this could be configured:
 * - if the feature is disabled at the server level, there's no way to configure
 *   it at all
 * - if a specific provider is configured at the server level (or there is only
 *   one option avaiable), then the only option is to toggle the feature off
 *   for all projects or to let them decide
 * - if the server configuration is ProviderDefault.ANY and there's more than one option, then
 *   the user can select to disable the feature, pick a specific configuration,
 *   or let the project decide
 */
export class OrgProviderConfigurator<E extends Provider> {
    constructor(
        private readonly service: ProvidedService<E>,
        private readonly org: Organization,
    ) {}
    canConfigure(): boolean {
        return this.service.getServerSetting() !== ProviderDefault.NONE;
    }
    build(): HTMLDivElement {
        const serverOption = this.service.getServerSetting();
        if (serverOption === ProviderDefault.NONE) {
            // This feature is disabled at the server level - don't show it.
            return null;
        }
        const currSetting = this.service.getOrgSetting(this.org);
        const availableOptions = this.service.allProviders;
        // This feature isn't disabled at the server level - allow the user to configure
        // whether to enforce a setting across all projects, and if so, what
        // that setting is.
        // eslint-disable-next-line prefer-const
        let optionsDropdown: ToggledSingleSelect<Base.Primitive<string>>;
        const dropdownOptions: (E | ProviderDefault.NONE)[] = [
            ProviderDefault.NONE,
            ...availableOptions,
        ];
        const overrideToggle = Checkbox.asToggleSlider({
            onChange: (state) => {
                optionsDropdown.setDisabled(!state);
                const provider = state
                    ? dropdownOptions[optionsDropdown.getCurrentIndex()]
                    : ProviderDefault.ANY;
                this.updateOrgValue(provider);
            },
            state: currSetting !== ProviderDefault.ANY,
        });
        const dropdownDiv = Dom.div({
            style: { marginLeft: "8px", marginTop: "-3px", userSelect: "none" },
        });
        const dropdownSection = Dom.div(
            { style: { display: "flex" } },
            overrideToggle.getNode(),
            "Override all projects' settings to",
            dropdownDiv,
        );
        const providerOptions = dropdownOptions.map(
            (v) => new Base.Primitive<string>(this.service.displayProvider(v)),
        );
        const currIndex =
            currSetting === ProviderDefault.ANY ? 0 : dropdownOptions.indexOf(currSetting);
        optionsDropdown = new ToggledSingleSelect({
            parent: dropdownDiv,
            options: providerOptions,
            default: providerOptions[currIndex],
            onChange: (_, index) => {
                const provider = overrideToggle.isSet()
                    ? dropdownOptions[index]
                    : ProviderDefault.ANY;
                this.promptUpdateOrgValue(provider).then(() => {
                    optionsDropdown.setAlternateDisplayText(providerOptions[index].display());
                });
            },
            width: 180,
            alternateDisabledDisplayText: this.service.displayProvider(ProviderDefault.NONE),
        });
        optionsDropdown.setDisabled(!overrideToggle.isSet());
        return dropdownSection;
    }
    private promptUpdateOrgValue(val: E | ProviderDefault): Promise<void> {
        if (
            this.service.getOrgSetting(this.org) === ProviderDefault.NONE
            && val !== ProviderDefault.NONE
        ) {
            return new Promise((resolve, reject) =>
                QueryDialog.create({
                    title: `Enable ${this.service.name}`,
                    prompt: enablePrompt(this.service.name, this.service.helpUrl),
                    submitText: "Enable",
                    onSubmit: () => {
                        this.updateOrgValue(val).then(resolve, reject);
                        return true;
                    },
                    onCancel: () => {
                        reject();
                        return true;
                    },
                }),
            );
        } else {
            return this.updateOrgValue(val);
        }
    }
    private updateOrgValue(val: E | ProviderDefault): Promise<void> {
        return Rest.post(this.service.orgUpdateEndpoint, {
            orgId: this.org.id,
            provider: val,
        }).then(() => {
            this.service.setOrgSetting(this.org, val);
            Base.publish(this.org);
        });
    }
}

function enablePrompt(featureName: string, helpUrl: string): HTMLDivElement {
    const infoLink = Dom.a(
        {
            class: "everblue-link",
            href: helpUrl,
            target: "_blank",
        },
        featureName,
    );
    const supportLink = Dom.a(
        {
            class: "everblue-link",
            href: "mailto:" + JSP_PARAMS.Help.supportEmail,
            target: "_blank",
        },
        JSP_PARAMS.Help.supportEmail,
    );
    return Dom.div(
        "If you have questions about enabling ",
        infoLink,
        ", contact ",
        supportLink,
        ".",
    );
}

/**
 * This class (and its concrete subclasses) is used to configure a provider at
 * the project level.
 * Given the server-level and org-level configurations,
 * there are a few different possible ways this could be configured:
 * - if the feature is disabled at the server level or there are no options,
 *   don't show anything at all
 * - if the feature is disabled at the org level, tell the user but they can't
 *   configure anything
 * - if a specific provider is configured at the server or org level (or there is only
 *   one option avaiable), then the only option is to toggle the feature on or off
 * - if the server and org configuration is ProviderDefault.ANY and there's more than one option, then
 *   the user can select to disable the feature or pick a specific configuration,
 */
export class ProjectProviderConfigurator<E extends Provider> {
    constructor(
        private readonly service: ProvidedService<E>,
        private readonly project: Project.CurrentProject,
        private readonly org: Organization,
        private readonly includeProviderInLabel: boolean = true,
    ) {}
    canConfigure(): boolean {
        return this.service.getServerSetting() !== ProviderDefault.NONE;
    }
    build(): HTMLElement {
        const serverSetting = this.service.getServerSetting();
        if (serverSetting == ProviderDefault.NONE) {
            // Cannot enable this feature.
            return null;
        }
        const orgProvider = resolveOrgProvider(serverSetting, this.service.getOrgSetting(this.org));
        const currProvider = this.service.getProjectSetting(this.project);
        if (orgProvider === ProviderDefault.NONE) {
            // The feature is disabled at the org level.
            return Dom.div("This functionality is disabled at the Organization level.");
        } else if (orgProvider === ProviderDefault.ANY && this.service.allProviders.length > 1) {
            // More than one option. Make a dropdown.
            const dropdownOptions: (E | ProviderDefault.NONE)[] = [
                ProviderDefault.NONE,
                ...this.service.allProviders,
            ];
            const dropdownDiv = Dom.div();
            const dropdown = new ToggledSingleSelect({
                parent: dropdownDiv,
                options: dropdownOptions.map(
                    (option) => new Base.Primitive<string>(this.service.displayProvider(option)),
                ),
                onChange: (provider, index) => {
                    this.onUpdate(dropdownOptions[index]).catch(() => {
                        dropdown.setCurrentIndex(0, true);
                    });
                },
                width: 180,
                alternateDisabledDisplayText: this.service.displayProvider(ProviderDefault.NONE),
            });
            dropdown.setCurrentIndex(dropdownOptions.indexOf(currProvider), true);
            return dropdownDiv;
        } else {
            // Only one option - let them select it or disable the feature.
            const provider =
                orgProvider !== ProviderDefault.ANY ? orgProvider : this.service.allProviders[0];
            const providerStr = ` (provider: ${this.service.displayProvider(provider)})`;
            const checkbox = Checkbox.asToggleSlider({
                label:
                    `Enable ${this.service.name}`
                    + (this.includeProviderInLabel ? providerStr : ""),
                state: this.service.getProjectSetting(this.project) !== ProviderDefault.NONE,
                onChange: (enabled: boolean) => {
                    this.onUpdate(enabled ? provider : ProviderDefault.NONE).catch(() =>
                        checkbox.set(false, true),
                    );
                },
            });
            return Dom.div({ class: "configurator" }, checkbox.getNode());
        }
    }
    private onUpdate(provider: E | ProviderDefault.NONE): Promise<void> {
        if (
            this.service.getProjectSetting(this.project) === ProviderDefault.NONE
            && provider !== ProviderDefault.NONE
        ) {
            return new Promise((resolve, reject) => {
                QueryDialog.create({
                    title: `Enable ${this.service.name}`,
                    prompt: enablePrompt(this.service.name, this.service.helpUrl),
                    submitText: "Enable",
                    onSubmit: () => {
                        this.updateProjectValue(provider).then(resolve, reject);
                        return true;
                    },
                    onCancel: () => {
                        reject();
                        return true;
                    },
                });
            });
        } else {
            return this.updateProjectValue(provider);
        }
    }
    private updateProjectValue(val: E | ProviderDefault.NONE): Promise<void> {
        return Rest.post(Project.CURRENT.url(this.service.projUpdateEndpoint), {
            provider: val,
        }).then(() => {
            this.service.setProjectSetting(this.project, val);
            Base.publish(Project.CURRENT);
        });
    }
}
